<mat-card
    class="trinta-card order-statistics-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Order Statistics
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                <i class="ri-more-2-fill"></i>
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    Last Day
                </button>
                <button mat-menu-item>
                    Last Week
                </button>
                <button mat-menu-item>
                    Last Month
                </button>
                <button mat-menu-item>
                    Last Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="d-md-flex align-items-center justify-content-between">
            <ul class="p-0 m-0 list-unstyled">
                <li class="position-relative d-inline-block">
                    <div class="dot"></div>
                    <span class="d-block text-gray">
                        Orders
                    </span>
                    <h6 class="m-0 fw-medium">
                        %45
                    </h6>
                </li>
                <li class="position-relative d-inline-block">
                    <div class="dot"></div>
                    <span class="d-block text-gray">
                        Profit
                    </span>
                    <h6 class="m-0 fw-medium">
                        %32
                    </h6>
                </li>
                <li class="position-relative d-inline-block">
                    <div class="dot"></div>
                    <span class="d-block text-gray">
                        Payout
                    </span>
                    <h6 class="m-0 fw-medium">
                        %28
                    </h6>
                </li>
                <li class="position-relative d-inline-block">
                    <div class="dot"></div>
                    <span class="d-block text-gray">
                        Customer
                    </span>
                    <h6 class="m-0 fw-medium">
                        %87
                    </h6>
                </li>
            </ul>
            <div class="chart">
                <apx-chart
                    [series]="chartOptions.series!"
                    [chart]="chartOptions.chart!"
                    [plotOptions]="chartOptions.plotOptions!"
                    [colors]="chartOptions.colors!"
                    [labels]="chartOptions.labels!"
                ></apx-chart>
            </div>
        </div>
    </mat-card-content>
</mat-card>