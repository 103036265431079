<mat-card
    class="trinta-card sales-overview-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Sales Overview
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <span class="trending d-block text-black position-relative">
                <i class="material-symbols-outlined">trending_up</i> <span class="fw-semibold">30.5k</span> Total Earning
            </span>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <mat-tab-group>
            <mat-tab label="Fashion">
                <div class="row">
                    <div class="col-xl-6 col-xxl-3 col-xxxl-6 col-lg-3 col-sm-6">
                        <div class="item d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <a routerLink="/" class="image position-relative text-center d-block">
                                    <img src="assets/images/sales-overview/t-shirt.png" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="fw-semibold d-block">
                                        T-Shirts
                                    </a>
                                    <span class="d-block text-gray">
                                        $29,000
                                    </span>
                                </div>
                            </div>
                            <span class="d-block text-primary fw-medium number">
                                252
                            </span>
                        </div>
                    </div>
                    <div class="col-xl-6 col-xxl-3 col-xxxl-6 col-lg-3 col-sm-6">
                        <div class="item d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <a routerLink="/" class="image position-relative text-center d-block">
                                    <img src="assets/images/sales-overview/watch.png" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="fw-semibold d-block">
                                        Watches
                                    </a>
                                    <span class="d-block text-gray">
                                        $22,314
                                    </span>
                                </div>
                            </div>
                            <span class="d-block text-primary fw-medium number">
                                147
                            </span>
                        </div>
                    </div>
                    <div class="col-xl-6 col-xxl-3 col-xxxl-6 col-lg-3 col-sm-6">
                        <div class="item d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <a routerLink="/" class="image position-relative text-center d-block">
                                    <img src="assets/images/sales-overview/shoe.png" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="fw-semibold d-block">
                                        Shoes
                                    </a>
                                    <span class="d-block text-gray">
                                        $24,421
                                    </span>
                                </div>
                            </div>
                            <span class="d-block text-primary fw-medium number">
                                68
                            </span>
                        </div>
                    </div>
                    <div class="col-xl-6 col-xxl-3 col-xxxl-6 col-lg-3 col-sm-6">
                        <div class="item d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <a routerLink="/" class="image position-relative text-center d-block">
                                    <img src="assets/images/sales-overview/bag.png" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="fw-semibold d-block">
                                        Bags
                                    </a>
                                    <span class="d-block text-gray">
                                        $12,245
                                    </span>
                                </div>
                            </div>
                            <span class="d-block text-primary fw-medium number">
                                2,155
                            </span>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Electronics">
                <div class="row">
                    <div class="col-xl-6 col-xxl-3 col-xxxl-6 col-lg-3 col-sm-6">
                        <div class="item d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <a routerLink="/" class="image position-relative text-center d-block">
                                    <img src="assets/images/sales-overview/t-shirt.png" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="fw-semibold d-block">
                                        Mobiles
                                    </a>
                                    <span class="d-block text-gray">
                                        $29,000
                                    </span>
                                </div>
                            </div>
                            <span class="d-block text-primary fw-medium number">
                                123
                            </span>
                        </div>
                    </div>
                    <div class="col-xl-6 col-xxl-3 col-xxxl-6 col-lg-3 col-sm-6">
                        <div class="item d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <a routerLink="/" class="image position-relative text-center d-block">
                                    <img src="assets/images/sales-overview/watch.png" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="fw-semibold d-block">
                                        Wearables
                                    </a>
                                    <span class="d-block text-gray">
                                        $22,314
                                    </span>
                                </div>
                            </div>
                            <span class="d-block text-primary fw-medium number">
                                321
                            </span>
                        </div>
                    </div>
                    <div class="col-xl-6 col-xxl-3 col-xxxl-6 col-lg-3 col-sm-6">
                        <div class="item d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <a routerLink="/" class="image position-relative text-center d-block">
                                    <img src="assets/images/sales-overview/shoe.png" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="fw-semibold d-block">
                                        Laptops
                                    </a>
                                    <span class="d-block text-gray">
                                        $24,421
                                    </span>
                                </div>
                            </div>
                            <span class="d-block text-primary fw-medium number">
                                100
                            </span>
                        </div>
                    </div>
                    <div class="col-xl-6 col-xxl-3 col-xxxl-6 col-lg-3 col-sm-6">
                        <div class="item d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <a routerLink="/" class="image position-relative text-center d-block">
                                    <img src="assets/images/sales-overview/bag.png" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="fw-semibold d-block">
                                        Scanners
                                    </a>
                                    <span class="d-block text-gray">
                                        $12,245
                                    </span>
                                </div>
                            </div>
                            <span class="d-block text-primary fw-medium number">
                                421
                            </span>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Furniture">
                <div class="row">
                    <div class="col-xl-6 col-xxl-3 col-xxxl-6 col-lg-3 col-sm-6">
                        <div class="item d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <a routerLink="/" class="image position-relative text-center d-block">
                                    <img src="assets/images/sales-overview/t-shirt.png" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="fw-semibold d-block">
                                        Tables
                                    </a>
                                    <span class="d-block text-gray">
                                        $29,000
                                    </span>
                                </div>
                            </div>
                            <span class="d-block text-primary fw-medium number">
                                12
                            </span>
                        </div>
                    </div>
                    <div class="col-xl-6 col-xxl-3 col-xxxl-6 col-lg-3 col-sm-6">
                        <div class="item d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <a routerLink="/" class="image position-relative text-center d-block">
                                    <img src="assets/images/sales-overview/watch.png" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="fw-semibold d-block">
                                        Beds
                                    </a>
                                    <span class="d-block text-gray">
                                        $22,314
                                    </span>
                                </div>
                            </div>
                            <span class="d-block text-primary fw-medium number">
                                15
                            </span>
                        </div>
                    </div>
                    <div class="col-xl-6 col-xxl-3 col-xxxl-6 col-lg-3 col-sm-6">
                        <div class="item d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <a routerLink="/" class="image position-relative text-center d-block">
                                    <img src="assets/images/sales-overview/shoe.png" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="fw-semibold d-block">
                                        Chairs
                                    </a>
                                    <span class="d-block text-gray">
                                        $24,421
                                    </span>
                                </div>
                            </div>
                            <span class="d-block text-primary fw-medium number">
                                21
                            </span>
                        </div>
                    </div>
                    <div class="col-xl-6 col-xxl-3 col-xxxl-6 col-lg-3 col-sm-6">
                        <div class="item d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <a routerLink="/" class="image position-relative text-center d-block">
                                    <img src="assets/images/sales-overview/bag.png" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="fw-semibold d-block">
                                        Desks
                                    </a>
                                    <span class="d-block text-gray">
                                        $12,245
                                    </span>
                                </div>
                            </div>
                            <span class="d-block text-primary fw-medium number">
                                55
                            </span>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Accessories">
                <div class="row">
                    <div class="col-xl-6 col-xxl-3 col-xxxl-6 col-lg-3 col-sm-6">
                        <div class="item d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <a routerLink="/" class="image position-relative text-center d-block">
                                    <img src="assets/images/sales-overview/t-shirt.png" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="fw-semibold d-block">
                                        Boots
                                    </a>
                                    <span class="d-block text-gray">
                                        $29,000
                                    </span>
                                </div>
                            </div>
                            <span class="d-block text-primary fw-medium number">
                                72
                            </span>
                        </div>
                    </div>
                    <div class="col-xl-6 col-xxl-3 col-xxxl-6 col-lg-3 col-sm-6">
                        <div class="item d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <a routerLink="/" class="image position-relative text-center d-block">
                                    <img src="assets/images/sales-overview/watch.png" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="fw-semibold d-block">
                                        Belts
                                    </a>
                                    <span class="d-block text-gray">
                                        $22,314
                                    </span>
                                </div>
                            </div>
                            <span class="d-block text-primary fw-medium number">
                                99
                            </span>
                        </div>
                    </div>
                    <div class="col-xl-6 col-xxl-3 col-xxxl-6 col-lg-3 col-sm-6">
                        <div class="item d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <a routerLink="/" class="image position-relative text-center d-block">
                                    <img src="assets/images/sales-overview/shoe.png" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="fw-semibold d-block">
                                        Gloves
                                    </a>
                                    <span class="d-block text-gray">
                                        $24,421
                                    </span>
                                </div>
                            </div>
                            <span class="d-block text-primary fw-medium number">
                                14
                            </span>
                        </div>
                    </div>
                    <div class="col-xl-6 col-xxl-3 col-xxxl-6 col-lg-3 col-sm-6">
                        <div class="item d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <a routerLink="/" class="image position-relative text-center d-block">
                                    <img src="assets/images/sales-overview/bag.png" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="fw-semibold d-block">
                                        Bracelets
                                    </a>
                                    <span class="d-block text-gray">
                                        $12,245
                                    </span>
                                </div>
                            </div>
                            <span class="d-block text-primary fw-medium number">
                                21
                            </span>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>