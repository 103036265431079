const thDict = {
    'Account recovery requires verified contact information': 'การกู้คืนบัญชีต้องมีข้อมูลติดต่อที่ได้รับการยืนยันแล้ว',
    'Add your Profile': 'เพิ่มโปรไฟล์ของคุณ',
    'Add your Website': 'เพิ่มเว็บไซต์ของคุณ',
    'Back to Sign In': 'กลับไปที่การเข้าสู่ระบบ',
    'Change Password': 'เปลี่ยนรหัสผ่าน',
    Changing: 'กำลังเปลี่ยน',
    Code: 'รหัส',
    'Confirm Password': 'ยืนยันรหัสผ่าน',
    'Please confirm your Password': 'กรุณายืนยันรหัสผ่านของคุณ',
    'Confirm Sign Up': 'ยืนยันการลงทะเบียน',
    'Confirm SMS Code': 'ยืนยันรหัส SMS',
    'Confirm MFA Code': 'ยืนยันรหัส MFA',
    'Confirm TOTP Code': 'ยืนยันรหัส TOTP',
    Confirm: 'ยืนยัน',
    'Confirmation Code': 'รหัสยืนยัน',
    Confirming: 'กำลังยืนยัน',
    'Create a new account': 'สร้างบัญชีใหม่',
    'Create Account': 'สร้างบัญชี',
    'Creating Account': 'กำลังสร้างบัญชี',
    'Dismiss alert': 'ปิดการแจ้งเตือน',
    Email: 'อีเมล',
    'Enter your Birthdate': 'กรอกวันเกิดของคุณ',
    'Enter your code': 'กรอกรหัสของคุณ',
    'Enter your Confirmation Code': 'กรอกรหัสยืนยันของคุณ',
    'Enter your Email': 'กรอกอีเมลของคุณ',
    'Enter your Family Name': 'กรอกนามสกุลของคุณ',
    'Enter your Given Name': 'กรอกชื่อของคุณ',
    'Enter your Middle Name': 'กรอกชื่อกลางของคุณ',
    'Enter your Name': 'กรอกชื่อของคุณ',
    'Enter your Nickname': 'กรอกชื่อเล่นของคุณ',
    'Enter your Password': 'กรอกรหัสผ่านของคุณ',
    'Enter your email': 'กรอกอีเมลของคุณ',
    'Enter your phone number': 'กรอกหมายเลขโทรศัพท์ของคุณ',
    'Enter your Preferred Username': 'กรอกชื่อผู้ใช้ที่ต้องการ',
    'Enter your username': 'กรอกชื่อผู้ใช้ของคุณ',
    'Forgot password?': 'ลืมรหัสผ่าน?',
    'Forgot your password?': 'ลืมรหัสผ่านใช่หรือไม่?',
    'Hide password': 'ซ่อนรหัสผ่าน',
    'It may take a minute to arrive': 'อาจใช้เวลาสักครู่',
    Loading: 'กำลังโหลด',
    'New password': 'รหัสผ่านใหม่',
    or: 'หรือ',
    Password: 'รหัสผ่าน',
    'Phone Number': 'หมายเลขโทรศัพท์',
    'Resend Code': 'ส่งรหัสอีกครั้ง',
    'Reset your Password': 'รีเซ็ตรหัสผ่านของคุณ',
    'Reset your password': 'รีเซ็ตรหัสผ่านของคุณ',
    'Send code': 'ส่งรหัส',
    'Send Code': 'ส่งรหัส',
    Sending: 'กำลังส่ง',
    'Setup TOTP': 'ตั้งค่า TOTP',
    'Show password': 'แสดงรหัสผ่าน',
    'Sign in to your account': 'เข้าสู่ระบบบัญชีของคุณ',
    'Sign In with Amazon': 'เข้าสู่ระบบด้วย Amazon',
    'Sign In with Apple': 'เข้าสู่ระบบด้วย Apple',
    'Sign In with Facebook': 'เข้าสู่ระบบด้วย Facebook',
    'Sign In with Google': 'เข้าสู่ระบบด้วย Google',
    'Sign in': 'เข้าสู่ระบบ',
    'Sign In': 'เข้าสู่ระบบ',
    'Signing in': 'กำลังเข้าสู่ระบบ',
    Skip: 'ข้าม',
    Submit: 'ส่ง',
    Submitting: 'กำลังส่ง',
    Username: 'ชื่อผู้ใช้',
    'Verify Contact': 'ยืนยันการติดต่อ',
    Verify: 'ยืนยัน',
    'We Emailed You': 'เราได้ส่งอีเมลถึงคุณแล้ว',
    'We Sent A Code': 'เราได้ส่งรหัสแล้ว',
    'We Texted You': 'เราได้ส่ง SMS ถึงคุณแล้ว',
    'Your code is on the way. To log in, enter the code we emailed to': 'รหัสของคุณกำลังมา เพื่อเข้าสู่ระบบ กรุณากรอกรหัสที่เราส่งไปยังอีเมล',
    'Your code is on the way. To log in, enter the code we sent you': 'รหัสของคุณกำลังมา เพื่อเข้าสู่ระบบ กรุณากรอกรหัสที่เราส่งให้คุณ',
    'Your code is on the way. To log in, enter the code we texted to': 'รหัสของคุณกำลังมา เพื่อเข้าสู่ระบบ กรุณากรอกรหัสที่เราส่งไปยัง SMS',
    // Additional translations
    'An account with the given email already exists.': 'บัญชีที่ใช้อีเมลนี้มีอยู่แล้ว',
    'Confirm a Code': 'ยืนยันรหัส',
    'Confirm Sign In': 'ยืนยันการเข้าสู่ระบบ',
    'Create account': 'สร้างบัญชี',
    'Sign Up with Facebook': 'ลงทะเบียนด้วย Facebook',
    'Sign Up with Google': 'ลงทะเบียนด้วย Google',
    'Sign Up with Apple': 'ลงทะเบียนด้วย Apple',
    'Sign Up with Line': 'ลงทะเบียนด้วย Line',
    'Forgot Password': 'ลืมรหัสผ่าน',
    'Have an account? ': 'มีบัญชีอยู่แล้ว? ',
    'Incorrect username or password': 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
    'Invalid password format': 'รูปแบบรหัสผ่านไม่ถูกต้อง',
    'It may take a minute to arrive.': 'อาจใช้เวลาสักครู่ในการมาถึง',
    'Lost your code? ': 'หารหัสไม่เจอ? ',
    'New Password': 'รหัสผ่านใหม่',
    'No account? ': 'ไม่มีบัญชี? ',
    'Password attempts exceeded': 'เกินจำนวนครั้งที่อนุญาตให้ใส่รหัสผ่าน',
    'Reset password': 'รีเซ็ตรหัสผ่าน',
    'Reset Password': 'รีเซ็ตรหัสผ่าน',
    'Sign Out': 'ออกจากระบบ',
    'Sign Up': 'ลงทะเบียน',
    'User already exists': 'ผู้ใช้นี้มีอยู่แล้ว',
    'User does not exist': 'ไม่มีผู้ใช้นี้',
    'Username cannot be empty': 'ต้องใส่ชื่อผู้ใช้งาน',
};

export { thDict };
