import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
// import { InactivityService } from '../shared/services/inactivity.service';
// import { SessionService } from '../shared/services/session.service';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { fetchAuthSession } from 'aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    // private inactivityService: InactivityService,
    private _router: Router) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,): Promise<boolean | UrlTree> {

      try {
        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
        if(idToken) return true
        // this.inactivityService.onStopWatching();
        this._router.navigate(['/sign-in'])
      } catch (err) {
        console.log(err);
        // this.inactivityService.onStopWatching();
        this._router.navigate(['/sign-in'])
      }
      return false;
    }
  }