import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ToggleService } from '../common/header/toggle.service';
import { CommonModule, LocationStrategy, NgClass, PathLocationStrategy, isPlatformBrowser } from '@angular/common';
import { filter } from 'rxjs';
import { Router, NavigationCancel, NavigationEnd, RouterLink, RouterOutlet } from '@angular/router';
import { SidebarComponent } from '../common/sidebar/sidebar.component';
import { HeaderComponent } from '../common/header/header.component';
import { FooterComponent } from '../common/footer/footer.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { BalanceOverviewComponent } from '../dashboard/crm/balance-overview/balance-overview.component';
import { CrmComponent } from '../dashboard/crm/crm.component';
import { EcommerceComponent } from '../dashboard/ecommerce/ecommerce.component';
import { fetchAuthSession } from 'aws-amplify/auth';
import { HomeComponent } from '../ds-pages/home/home.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [RouterOutlet, CommonModule, RouterLink, SidebarComponent, HeaderComponent, FooterComponent, NgClass, MatCardModule, MatButtonModule, MatMenuModule, EcommerceComponent, HomeComponent ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]
})
export class LayoutComponent implements OnInit{
  title = 'Data Sentinel';
    routerSubscription: any;
    location: any;

    show = false;

    constructor(
        public router: Router,
        public toggleService: ToggleService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        this.toggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    // Toggle Service
    isToggled = false;

    // Dark Mode
    toggleTheme() {
        this.toggleService.toggleTheme();
    }

    // Settings Button Toggle
    toggle() {
        this.toggleService.toggle();
    }

    fadeIn = false;
    // ngOnInit
    async ngOnInit(){

        setTimeout(() => {
            this.fadeIn = true;
          }, 100);

        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
        console.log(idToken, accessToken)
        if(!idToken) {
            this.router.navigate(['/sign-in'])
        }
        this.show = true;


        if (isPlatformBrowser(this.platformId)) {
            this.recallJsFuntions();
        }
    }

    // recallJsFuntions
    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe((event: any) => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            this.scrollToTop();
        });
    }
    scrollToTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

}
