const ptDict = {
    'Account recovery requires verified contact information': 'A recuperação da conta requer informações de contato verificadas',
    'Add your Profile': 'Adicione seu Perfil',
    'Add your Website': 'Adicione seu Website',
    'Back to Sign In': 'Voltar para Entrar',
    'Change Password': 'Mudar senha',
    Changing: 'Mudando',
    Code: 'Código',
    'Confirm Password': 'Confirme a Senha',
    'Confirm Sign Up': 'Confirmar inscrição',
    'Confirm SMS Code': 'Confirme o código SMS',
    'Confirm MFA Code': 'Confirme o código MFA',
    'Confirm TOTP Code': 'Confirme o código TOTP',
    Confirm: 'confirme',
    'Confirmation Code': 'Código de confirmação',
    Confirming: 'Confirmando',
    'Create a new account': 'Criar uma nova conta',
    'Create Account': 'Criar Conta',
    'Creating Account': 'Criando conta',
    'Dismiss alert': 'Descartar alerta',
    Email: 'O email',
    'Enter your Birthdate': 'Digite sua Data de Nascimento',
    'Enter your code': 'Insira seu código',
    'Enter your Confirmation Code': 'Digite seu código de confirmação',
    'Enter your Email': 'Digite seu e-mail',
    'Enter your Family Name': 'Digite seu Sobrenome',
    'Enter your Given Name': 'Digite seu Primeiro Nome',
    'Enter your Middle Name': 'Digite seu Nome do Meio',
    'Enter your Name': 'Digite seu Nome',
    'Enter your Nickname': 'Digite seu Apelido',
    'Enter your Password': 'Digite sua senha',
    'Enter your phone number': 'Digite seu número de telefone',
    'Enter your Preferred Username': 'Digite seu nome de usuário preferido',
    'Enter your username': 'Digite seu nome de usuário',
    'Forgot password?': 'Esqueceu a senha?',
    'Forgot your password?': 'Esqueceu sua senha?',
    'Hide password': 'Esconder a senha',
    'It may take a minute to arrive': 'Pode levar um minuto para chegar',
    Loading: 'Carregando',
    'New password': 'Nova Senha',
    or: 'ou',
    Password: 'Senha',
    'Phone Number': 'Número de telefone',
    'Please confirm your Password': 'Por favor confirme sua Senha',
    'Resend Code': 'Reenviar código',
    'Reset your password': 'Redefina sua senha',
    'Reset your Password': 'Redefina sua senha',
    'Send code': 'Enviar código',
    'Send Code': 'Enviar código',
    Sending: 'Enviando',
    'Setup TOTP': 'Configurar TOTP',
    'Show password': 'Mostrar senha',
    'Sign in to your account': 'Faça login em sua conta',
    'Sign In with Amazon': 'Entrar com a Amazon',
    'Sign In with Apple': 'Entrar com a Apple',
    'Sign In with Facebook': 'Entrar com o Facebook',
    'Sign In with Google': 'Faça login no Google',
    'Sign in': 'Entrar',
    'Sign In': 'Entrar',
    'Signing in': 'Entrando',
    Skip: 'Pular',
    Submit: 'Enviar',
    Submitting: 'Enviando',
    Username: 'Nome do usuário',
    'Verify Contact': 'Verificar contato',
    Verify: 'Verificar',
    'We Emailed You': 'Enviamos um e-mail para você',
    'We Sent A Code': 'Enviamos um código',
    'We Texted You': 'Enviamos um SMS para você',
    'Your code is on the way. To log in, enter the code we emailed to': 'Seu código está a caminho. Para fazer login, insira o código para o qual enviamos um e-mail',
    'Your code is on the way. To log in, enter the code we sent you': 'Seu código está a caminho. Para fazer login, insira o código que enviamos para você',
    'Your code is on the way. To log in, enter the code we texted to': 'Seu código está a caminho. Para fazer login, insira o código para o qual enviamos uma mensagem de texto',
    // Additional translations provided by customers
    'An account with the given email already exists.': 'Já existe uma conta com o email utilizado.',
    'Confirm a Code': 'Confirmar um Código',
    'Confirm Sign In': 'Confirmar Início de Sessão',
    'Forgot Password': 'Esqueci Minha Senha',
    'Incorrect username or password.': 'Nome de usuário ou senha incorreta',
    'Invalid password format': 'Formato de senha inválido',
    'Invalid phone number format': 'Formato de número de telefone inválido',
    'Loading...': 'Carregando...',
    'New Password': 'Nova Senha',
    'Resend a Code': 'Reenviar um Código',
    'Sign Out': 'Sair',
    'Sign Up with Amazon': 'Criar Conta com a Amazon',
    'Sign Up with Apple': 'Criar Conta com a Apple',
    'Sign Up with Facebook': 'Criar Conta com o Facebook',
    'Sign Up with Google': 'Criar Conta com o Google',
    'Sign Up': 'Criar Conta',
    'User already exists': 'Usuário já existe',
    'User does not exist': 'Usuário não existe',
    'Username cannot be empty': 'Nome de usuário não pode estar vazio',
    'Your passwords must match': 'Suas senhas devem ser iguais',
};

export { ptDict };
