import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { signOut } from 'aws-amplify/auth';
import { IdleUserService } from './inactivity.service';

@Injectable({
  providedIn: 'root'
})
export class AmplifyService {

  constructor(
    private _router: Router,
    private _idle: IdleUserService
  ) { }

    //Pasar a otro servicio
  public async logout() {
    try {
      await signOut();
      //Limpiar store, stop watching en el userIdle
      // this._store.dispatch(setSelectedOrganization({ selectedOrganization: ""}))
      // await this._userIdleService.stopTimer();
      // await this._userIdleService.stopWatching();
      // this._sessionService.clearSession();
      this._idle.stopCounting();
      this._router.navigate(['/sign-in'])

    } catch (error) {
      console.log('error signing out: ', error);
    }
  // this.removeHandShakeKeys();
  // window.location.href = `${this.baseAmazonCognitoURI}/logout?client_id=${this.appClientId}&logout_uri=${this.redirectLogoutURI}`;

  }

}
