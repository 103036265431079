import { Component, effect } from '@angular/core';
import { CommonModule, DatePipe, NgClass } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { FeathericonsModule } from '../../icons/feathericons/feathericons.module';
import { Router, RouterLink } from '@angular/router';
import { ToggleService } from './toggle.service';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { AmplifyService } from '../../shared/services/amplify.service';
import { IUserInfoCognito } from '../../ds-pages/models/UserInfo.model';
import { AppStateService } from '../../ds-pages/state/app-state.service';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [CommonModule, FeathericonsModule, MatButtonModule, MatMenuModule, RouterLink, NgClass],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    providers: [
        DatePipe
    ]
})
export class HeaderComponent {

    userInfo: IUserInfoCognito | undefined = undefined
    userRole = "";
    isSoporte = false;

    constructor(
        public toggleService: ToggleService,
        private datePipe: DatePipe,
        private router: Router,
        private _amplify: AmplifyService,
        private _state: AppStateService
    ) {
        this.toggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
        effect(() => {
            this.isSoporte = this._state.isSoporte() && this._state.isDesarrollo();
          });

    }

    async ngOnInit() {
        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
        const idTokenPayload = idToken?.payload!;

        const userInfo = <IUserInfoCognito>{
            organizations: idTokenPayload["cognito:groups"],
            username: idTokenPayload["email"],
            email: idTokenPayload["email"],
            userSub: idTokenPayload["sub"],
            given_name: idTokenPayload["given_name"],
            name: idTokenPayload["name"],
            phoneNumber: idTokenPayload["phone_number"]
        } 

        this.userInfo = userInfo;
    }

    // Toggle Service
    isToggled = false;
    toggle() {
        this.toggleService.toggle();
    }

    // Dark Mode
    toggleTheme() {
        this.toggleService.toggleTheme();
    }

    // Current Date
    currentDate: Date = new Date();
    formattedDate: any = this.datePipe.transform(this.currentDate, 'dd MMMM yyyy');

    async onLogout() {
        this._state.deleteState();
        this._amplify.logout()
    }

    isAdmin(): boolean {
        return this._state.isAdmin();
    }

}