import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { environment as env } from '../../../environments/environment';
import { signOut } from 'aws-amplify/auth';
import { Router } from '@angular/router';

export enum IdleUserTimes {
  IdleTime = 30 * 60 * 1000,
  CountdownTime = 5000
}

@Injectable({
  providedIn: 'root'
})
export class IdleUserService {
  private timeoutId: any;
  private countdownId: any;
  private countdownValue: number;

  userInactive: Subject<boolean> = new Subject();

  constructor(public dialog: MatDialog, private _router: Router) {
    this.eventHandler = this.reset.bind(this);
  }

  private eventHandler: () => void;

  initListener() {
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('click', this.eventHandler);
    window.addEventListener('keypress', this.eventHandler);
    window.addEventListener('DOMMouseScroll', this.eventHandler);
    window.addEventListener('mousewheel', this.eventHandler);
    window.addEventListener('touchmove', this.eventHandler);
    window.addEventListener('MSPointerMove', this.eventHandler);
  }

  public startCounting() {
    console.log('startCounting');
    this.reset();
    this.initListener();
  }

  public stopCounting() {
    console.log('stopCounting');
    clearTimeout(this.timeoutId);
    clearTimeout(this.countdownId);
    this.removeListener();
  }

  removeListener() {
    console.log('removeListener');
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('click', this.eventHandler);
    window.removeEventListener('keypress', this.eventHandler);
    window.removeEventListener('DOMMouseScroll', this.eventHandler);
    window.removeEventListener('mousewheel', this.eventHandler);
    window.removeEventListener('touchmove', this.eventHandler);
    window.removeEventListener('MSPointerMove', this.eventHandler);
  }

  reset() {
    console.log("Reset");
    clearTimeout(this.timeoutId);
    clearTimeout(this.countdownId);
    this.startIdleTimer();
  }

  startIdleTimer() {
    this.timeoutId = setTimeout(() => {
      console.log('Inactivity detected');
      this.startCountdown();
    }, IdleUserTimes.IdleTime);
  }

  startCountdown() {
    this.countdownValue = IdleUserTimes.CountdownTime / 1000;
    this.countdownId = setInterval(() => {
      this.countdownValue--;
      console.log('You will logout in:', this.countdownValue, 'seconds');
      if (this.countdownValue <= 0) {
        clearInterval(this.countdownId);
        console.log('User idle');
        this.userInactive.next(true);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            title: 'Confirmación',
            message: `Desea seguir operando o trabajando en el sitio?`,
            textBoton: ['Si', 'No'],
            textTimer: env.inactivity.countdown + 1
          }
        });

        dialogRef.afterClosed().subscribe(async resp => {
          if (!resp) {
            this.stopCounting();
            await signOut();
            this._router.navigate(['/sign-in']);
          } else {
            this.reset();
          }
        });
      }
    }, 1000);
  }
}
