import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppInterceptor } from './app.interceptor';

export const appConfig: ApplicationConfig = {
    providers: 
        [
            provideRouter(routes), 
            provideClientHydration(), 
            provideAnimationsAsync(), 
            importProvidersFrom(BrowserAnimationsModule),
            importProvidersFrom(ToastrModule.forRoot()),
            provideHttpClient(
                withInterceptorsFromDi(),
                withInterceptors([])
            ),
            { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
        ]
};