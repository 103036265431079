import { Component } from '@angular/core';
import { FormsModule, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterLink, Router } from '@angular/router';
import { FeathericonsModule } from '../../icons/feathericons/feathericons.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';
import { Amplify } from 'aws-amplify';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { Hub } from 'aws-amplify/utils';
import { fetchAuthSession } from 'aws-amplify/auth';
import { IdleUserService } from '../../shared/services/inactivity.service';
// import awsconfig from '../aws-exports';

@Component({
    selector: 'app-sign-in',
    standalone: true,
    imports: [RouterLink, MatButton, MatIconButton, FormsModule, MatFormFieldModule, MatInputModule, FeathericonsModule, MatCheckboxModule, ReactiveFormsModule, NgIf, AmplifyAuthenticatorModule],
    templateUrl: './sign-in.component.html',
    styleUrl: './sign-in.component.scss'
})
export class SignInComponent {

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private inactivity: IdleUserService
    ) {
        // Amplify.configure({
        //     Auth: {
        //       Cognito: {
        //         //  Amazon Cognito User Pool ID
        //         userPoolId: 'us-east-1_p96Mdgx7Q',
        //         // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        //         userPoolClientId: '1ub8jaboi60i2q9hjias8uai7p',
        //         // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        //         // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
        //         // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
        //         // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
        //         // signUpVerificationMethod: 'code', // 'code' | 'link'
        //         // loginWith: {
        //         //     // OPTIONAL - Hosted UI configuration
        //         //     oauth: {
        //         //     domain: 'https://web-dev-usuarios-gestionatupyme.auth.us-east-1.amazoncognito.com',
        //         //     scopes: [
        //         //         // 'phone',
        //         //         'email',
        //         //         'profile',
        //         //         'openid',
        //         //         'aws.cognito.signin.user.admin'
        //         //     ],
        //         //     redirectSignIn: ['http://localhost:4200/callback'],
        //         //     redirectSignOut: ['http://localhost:4200/logout'],
        //         //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        //         //     }
        //         // }
        //       },
        //     },
        //   })
        this.authForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(8)]],
        });

        this.checkSession();

        this.listenToAuthEvents();
    }

    async checkSession() {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
        if(idToken) {
            this.inactivity.startCounting();
            this.router.navigate(['/tests/123-4'])
        }
    }

    // Password Hide
    hide = true;

    // Form
    authForm: FormGroup;
    onSubmit() {
        if (this.authForm.valid) {
            this.router.navigate(['/']);
        } else {
            console.log('Form is invalid. Please check the fields.');
        }
    }

    listenToAuthEvents() {
        Hub.listen('auth', async ({ payload } : any) => {
          switch (payload.event) {
            case 'signedIn':
            //   await this.setStore()
            // this.verifyToken();
            //   this.userActive();
            //   this.manageAppVersionStep1();
              this.inactivity.startCounting();
              this.router.navigate(['/tests/123-4'])
              break;
            
            case 'tokenRefresh':
              //Ponemos el nuevo token en el local storage para que lo consulte verifyToken
              const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
            //   if(idToken) {
            //     this._sessionService.setCurrentIdToken(idToken.toString());
            //   }
              break;
            }
        })
      }

}