<mat-card
    class="trinta-card top-selling-products-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Top Selling Products
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Month
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="products-slides">
            <owl-carousel-o [options]="productsSlides">
                <ng-template carouselSlide>
                    <div class="d-flex align-items-center">
                        <a routerLink="/" class="image d-block flex-shrink-0">
                            <img src="assets/images/products/product1.jpg" alt="product-image">
                        </a>
                        <div class="content flex-grow-1">
                            <h5>
                                <a routerLink="/">
                                    Smart Watch
                                </a>
                            </h5>
                            <span class="sale d-block text-black">
                                8,905 sale
                            </span>
                            <span class="price d-block text-black">
                                <span>$16,908</span> income this week
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="d-flex align-items-center">
                        <a routerLink="/" class="image d-block flex-shrink-0">
                            <img src="assets/images/products/product2.jpg" alt="product-image">
                        </a>
                        <div class="content flex-grow-1">
                            <h5>
                                <a routerLink="/">
                                    Smart Headphone
                                </a>
                            </h5>
                            <span class="sale d-block text-black">
                                2,313 sale
                            </span>
                            <span class="price d-block text-black">
                                <span>$10,432</span> income this week
                            </span>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
        <div class="chart">
            <apx-chart
                [series]="chartOptions.series!"
                [chart]="chartOptions.chart!"
                [xaxis]="chartOptions.xaxis!"
                [stroke]="chartOptions.stroke!"
                [dataLabels]="chartOptions.dataLabels!"
                [yaxis]="chartOptions.yaxis!"
                [colors]="chartOptions.colors!"
                [tooltip]="chartOptions.tooltip!"
                [markers]="chartOptions.markers!"
                [labels]="chartOptions.labels!"
                [legend]="chartOptions.legend!"
                [grid]="chartOptions.grid!"
                [title]="chartOptions.title!"
                [subtitle]="chartOptions.subtitle!"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>