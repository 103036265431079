
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, from, lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { fetchAuthSession } from 'aws-amplify/auth';
import { environment } from '../environments/environment';
import { AppStateService } from './ds-pages/state/app-state.service';

@Injectable({
    providedIn: 'root'
})


export class AppInterceptor implements HttpInterceptor {

    constructor(private _state: AppStateService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handle(req, next));
    }

    async handle(req: HttpRequest<any>, next: HttpHandler) {
        const state = this._state.getState()();
        const organization = state.organization;
        const email = state.email;
        const tokens = (await fetchAuthSession()).tokens;
        let request = req;
        if (tokens && tokens.idToken) {
            request = req.clone({
                setHeaders: {
                    'Authorization': 'Bearer ' + tokens.idToken.toString(),
                    'Content-Type': 'application/json',
                }
            });
            if(!req.url.includes(environment.apis.userAccess)) {
                request = request.clone({
                    setHeaders: {
                        'IDOrganizacion': organization!.toString(),
                        'IDUsuario': email!,
                        'RequestOrigin': 'WEB'
                    }
                });
            }
        } else {
            request = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });   
        }
    
        return await lastValueFrom(next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 401) {
                    // this.inactivityService.onStopWatching();
                    // this._cognitoService.logout();
                }

                return throwError(err);
            })));
      }

}