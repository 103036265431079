<mat-card
    class="trinta-card earnings-reports-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Earnings Reports
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                <i class="ri-more-2-fill"></i>
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    Last Day
                </button>
                <button mat-menu-item>
                    Last Week
                </button>
                <button mat-menu-item>
                    Last Month
                </button>
                <button mat-menu-item>
                    Last Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="earnings-reports-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Date Column -->
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef class="pl-0">
                            Date
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-dark pl-0">
                            {{element.date}}
                        </td>
                    </ng-container>

                    <!-- Item Count Column -->
                    <ng-container matColumnDef="itemCount">
                        <th mat-header-cell *matHeaderCellDef>
                            Item Count
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.itemCount}}
                        </td>
                    </ng-container>

                    <!-- Earnings Column -->
                    <ng-container matColumnDef="earnings">
                        <th mat-header-cell *matHeaderCellDef class="text-end pr-0">
                            Earnings
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-primary text-end pr-0">
                            {{element.earnings}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[7, 12, 18]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>