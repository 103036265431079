<div *ngIf="show"
    [ngClass]="{'blank-page': router.url === '/authentication' || router.url === '/authentication/sign-up' || router.url === '/authentication/forgot-password' || router.url === '/authentication/reset-password' || router.url === '/authentication/lock-screen' || router.url === '/authentication/logout' || router.url === '/authentication/confirm-email'}"
    [class.dark-theme]="toggleService.isDark()"
>

    <!-- Sidebar -->
    <!-- <app-sidebar></app-sidebar> -->

    <!-- Main Content -->
    <div
        class="main-content transition d-flex flex-column"
        [ngClass]="{'active': isToggled}"
    >
        <app-header></app-header>
        <div class="content opacity-0 transition-opacity duration-500 ease-in" [ngClass]="{'opacity-100': fadeIn}">
            <router-outlet></router-outlet>
        </div>

        <div class="flex-grow-1"></div>
        <app-footer></app-footer>
    </div>

</div>
