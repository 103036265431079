<mat-card
    class="trinta-card website-overview-card mb-25 bg-transparent border-none d-block position-relative z-1"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0 text-white">
                Mis Empresas
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <span class="trending d-block text-white position-relative">
                Realizar tests
            </span>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <mat-card class="empresas-table">
            <div style="margin: 24px">
                <app-basic-table></app-basic-table>
            </div>
        </mat-card>
    </mat-card-content>
</mat-card>