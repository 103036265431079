import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { Amplify } from "aws-amplify"
import { environment } from './environments/environment';



bootstrapApplication(AppComponent, appConfig)
    .catch((err) => console.error(err));


    Amplify.configure({
        Auth: {
          Cognito: {
            //  Amazon Cognito User Pool ID
            userPoolId: environment.cognito.userPoolID,
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolClientId: '1ub8jaboi60i2q9hjias8uai7p',
            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
            // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
            // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
            signUpVerificationMethod: 'code', // 'code' | 'link'
            loginWith: {
                // OPTIONAL - Hosted UI configuration
                oauth: {
                domain: 'https://web-dev-usuarios-gestionatupyme.auth.us-east-1.amazoncognito.com',
                scopes: [
                    // 'phone',
                    'email',
                    'profile',
                    'openid',
                    'aws.cognito.signin.user.admin'
                ],
                redirectSignIn: ['http://localhost:4200/callback'],
                redirectSignOut: ['http://localhost:4200/logout'],
                responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
                }
            }
          },
        },
      })