<mat-card
    class="trinta-card revenue-overview-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Revenue Overview
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Month
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="chart">
            <apx-chart
                [series]="chartOptions.series!"
                [chart]="chartOptions.chart!"
                [yaxis]="chartOptions.yaxis!"
                [xaxis]="chartOptions.xaxis!"
                [grid]="chartOptions.grid!"
                [markers]="chartOptions.markers!"
                [legend]="chartOptions.legend!"
                [labels]="chartOptions.labels!"
                [colors]="chartOptions.colors!"
                [stroke]="chartOptions.stroke!"
                [title]="chartOptions.title!"
                [plotOptions]="chartOptions.plotOptions!"
                [dataLabels]="chartOptions.dataLabels!"
                [fill]="chartOptions.fill!"
                [tooltip]="chartOptions.tooltip!"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>