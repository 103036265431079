declare let $: any;
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { ToggleService } from '../app/common/header/toggle.service';
import { SidebarComponent } from './common/sidebar/sidebar.component';
import { CommonModule, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { RouterOutlet, Router, NavigationCancel, NavigationEnd, RouterLink } from '@angular/router';
import { IdleUserService } from './shared/services/inactivity.service';
import { fetchAuthSession } from 'aws-amplify/auth';
import { UserService } from './ds-pages/services/user.service';
import { AppStateService } from './ds-pages/state/app-state.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, CommonModule, RouterLink, SidebarComponent, HeaderComponent, FooterComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit{

    title = 'Data Sentinel';
    routerSubscription: any;
    location: any;
    isLoading = true;

    constructor(
        public router: Router,
        public toggleService: ToggleService,
        private idleUserService: IdleUserService,
        private _userService: UserService,
        private _stateService: AppStateService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        this.toggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    // Toggle Service
    isToggled = false;

    // Dark Mode
    toggleTheme() {
        this.toggleService.toggleTheme();
    }

    // Settings Button Toggle
    toggle() {
        this.toggleService.toggle();
    }

    // ngOnInit
    async ngOnInit(){
        if (isPlatformBrowser(this.platformId)) {
            this.recallJsFuntions();
        }

        this.idleUserService.userInactive.subscribe(isIdle => {});
        const { selectedOrganization, organizations, email } = await this.checkSession();
        this.setAppState(selectedOrganization, organizations, email);
    }

    async checkSession() {
        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
        if(idToken) {
            this.idleUserService.startCounting();
            const idTokenPayload: any = idToken.payload!

            // Agregar logica de multiples orgs en soporte
            let selectedOrganization = "";
            const state = this._stateService.getState()();
            const stateOrganization = state.organization;
            const email = idTokenPayload["email"]?.toString();
            const organizations = idTokenPayload["cognito:groups"];

            if(organizations.length > 1 && stateOrganization === "") {
                this.isLoading = false;
                this.router.navigate(['/select-organization']);
            } else if (stateOrganization.length > 1 && stateOrganization !== "") {
                selectedOrganization = stateOrganization;
            } else {
                selectedOrganization  = organizations[0];
            }

            return { selectedOrganization, organizations, email }
        }
        this.isLoading = false;
        return { selectedOrganization: "", organizations: null, email: null }
    }

    // recallJsFuntions
    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            this.scrollToTop();
        });
    }
    scrollToTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    setAppState(selectedOrganization: string, organizations: string[], email: string) {
        this._userService.getUserAccess(selectedOrganization, email).subscribe(
            (res) => {
                this.isLoading = false;
                const accesos = res.availableAccess;
                this._stateService.setState(email, selectedOrganization, organizations, accesos);
            },
            error => {
                console.log("ERROR")
            }
        )
    }

}