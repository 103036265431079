<!-- Website Overview -->
<div style="margin-right: 17px;">

</div>
<app-website-overview />
<!-- 
<div class="row">
    <div class="col-xxl-4 col-md-12"> -->

        <!-- Top Selling Products -->
        <!-- <app-top-selling-products /> -->

    <!-- </div> -->
    <!-- <div class="col-xxl-8 col-md-12"> -->

        <!-- Revenue Overview -->
        <!-- <app-revenue-overview />

    </div>
</div> -->

<!-- <div class="row">
    <div class="col-xxxl-4 col-lg-6 col-md-12 order-xxl-1 order-xxxl-1"> -->

        <!-- Visits By Week -->
        <!-- <app-visits-by-week />

    </div>
    <div class="col-xxxl-4 col-lg-6 col-md-12 order-xxl-2 order-xxxl-2"> -->

        <!-- Order Statistics -->
        <!-- <app-order-statistics />

    </div>
    <div class="col-xxxl-4 col-md-12 order-xxl-4 order-xxxl-3"> -->

        <!-- Sales Overview -->
        <!-- <app-sales-overview />

    </div>
    <div class="col-xxxl-12 col-md-12 order-xxl-3 order-xxxl-4"> -->

        <!-- Recent Orders -->
        <!-- <app-recent-orders />

    </div>
    <div class="col-xxxl-4 col-md-12 order-xxl-5 order-xxxl-5"> -->

        <!-- Transactions History -->
        <!-- <app-transactions-history />

    </div>
    <div class="col-xxxl-4 col-lg-6 col-md-12 order-xxl-6 order-xxxl-6"> -->

        <!-- Sales By Locations -->
        <!-- <app-sales-by-locations />

    </div>
    <div class="col-xxxl-4 col-lg-6 col-md-12 order-xxl-7 order-xxxl-7"> -->

        <!-- Earnings Reports -->
        <!-- <app-earnings-reports />

    </div>
</div> -->