<mat-card
    class="trinta-card recent-orders-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Recent Orders
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="recent-orders-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Tracking No Column -->
                    <ng-container matColumnDef="trackingID">
                        <th mat-header-cell *matHeaderCellDef class="text-primary pl-0">
                            Tracking No
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-primary fw-medium pl-0">
                            {{element.trackingID}}
                        </td>
                    </ng-container>

                    <!-- Product Column -->
                    <ng-container matColumnDef="product">
                        <th mat-header-cell *matHeaderCellDef>
                            Product
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="product-info d-flex align-items-center">
                                <a routerLink="/" class="image d-block">
                                    <img [src]="element.product.img" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="d-block fw-semibold">
                                        {{element.product.title}}
                                    </a>
                                    <span class="d-block text-body">
                                        {{element.product.date}}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Customer Column -->
                    <ng-container matColumnDef="customer">
                        <th mat-header-cell *matHeaderCellDef>
                            Customer
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.customer}}
                        </td>
                    </ng-container>

                    <!-- Price Column -->
                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef>
                            Price
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.price}}
                        </td>
                    </ng-container>

                    <!-- Quantity Column -->
                    <ng-container matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef>
                            Quantity
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.quantity}}
                        </td>
                    </ng-container>

                    <!-- Vendor Column -->
                    <ng-container matColumnDef="vendor">
                        <th mat-header-cell *matHeaderCellDef>
                            Vendor
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.vendor}}
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="text-end pr-0">
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-end pr-0">
                            <span class="trinta-badge fw-medium pending d-inline-block" *ngIf="element.status.pending">
                                {{element.status.pending}}
                            </span>
                            <span class="trinta-badge fw-medium delivered d-inline-block" *ngIf="element.status.delivered">
                                {{element.status.delivered}}
                            </span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>